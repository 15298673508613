/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {
    async getServerInfo(payload) {
        // await AuthToken.getCurrentAuth();
        const response = await Api().post(`api/dashboard/google/get-server-info`, payload);
        return response;
    },
    async getServerReboot() {
        // await AuthToken.getCurrentAuth();
        const response = await Api().post(`api/dashboard/google/server-reboot`);
        return response;
    },
    async getServerOptimize() {
        // await AuthToken.getCurrentAuth();
        const response = await Api().post(`api/dashboard/google/server-custom-optmize`);
        return response;
    },

    //  product Section Start
    async generate2FA(payload) {
        // await AuthToken.getCurrentAuth();
        const response = await Api().post(`api/dashboard/google/generate`, payload);
        return response;
    },

    async verify2FA(payload) {
        // await AuthToken.getCurrentAuth();
        const response = await Api().get(`api/dashboard/google/verify`, payload);
        return response;
    },
    //  product Section End
}