import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { plan_columns } from "../../../../components/tables/tableheader";
import { planMasterSchema } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../../helper/commonHelper";
import planServices from "../../../../ApiServices/planServices";
import { handleDynamicFileImport } from '../../../../helper/dynamicImport';
import Select from "react-select";
import prodServices from "../../../../ApiServices/prodServices";

function PlanList() {
  const pages = [{ title: "Plan List", href: "/plan", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [plan_id, setPlanid] = useState("");
  const [planList, setPlanList] = useState([]);
  const [prodList, setProductList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentProductID, setCurrentProductID] = useState('')

  const { prod_id: prod_id } = useParams();


  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    plan_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    plan_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState([{
    label: "MONTHLY",
    value: "MONTHLY",
  }]);

  const [plansTypeList, setPlansTypeList] = useState([
    {
      label: "MONTHLY",
      value: "MONTHLY",
    },
    {
      label: "YEARLY",
      value: "YEARLY",
    },
    {
      label: "FREE",
      value: "FREE",
    },
  ]);
  const handlePlanType = (e) => {
    setSelectedPlan(e);
  };


  const [importLoader, setImportLoader] = useState(false);
  const fileInputRef = useRef(null);

  const [error, setError] = useState(null);

  // // Define your entity map for dynamic import (example)
  const entityPlanMap = {
    PlanName: 'PlanName',
    ProductName: 'ProductName',
    PlanDesc: 'PlanDesc',
    PlanExpiry: 'PlanExpiry',
    PlanOnboardingCharge: 'PlanOnboardingCharge',
    PlanSubscriptionPrice: 'PlanSubscriptionPrice',
    PlanPeriod: 'PlanPeriod',
  };
  // console.log('errors=>',errors)
  // // Define the entity you are working with (in this case 'barcode')
  const entityPlan = "Plan";

  const handlePlanButtonClick = () => {
    fileInputRef.current.click();
  };

  const handlePlanImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityPlanMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importEntities(importData);
          } else {
            setImportLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importEntities = (data) => {
    const payload = { excel_data: data };
    console.log('importEntities payload=>', payload, data)

    planServices.importPlan(payload, entityPlan)
      .then((response) => {
        setImportLoader(false);
        if (response.status === 200) {
          toast.success(`${entityPlan} imported successfully`);
          getAllPlans(3);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportLoader(false);
        toast.error(`Error while importing ${entityPlan}`);
      });
  };

  useEffect(() => {
    document.title = "EdgeFin •  Plans";
    setCurrentProductID(prod_id)
    getAllPlans(3);
    getAllProducts(1);
  }, []);
  const getAllProducts = async (payload) => {
    setIsLoading(true);
    try {
      const res = await prodServices.getAllProduct(payload)
      if (res.status) {
        console.log("res.data.data=>", res.data.data?.products)
        setProductList(res?.data?.data?.products);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const getAllPlans = async (payload) => {
    setIsLoading(true);
    try {
      const res = await planServices.getAllPlan(payload)
      if (res.status) {
        setPlanList(res?.data?.data?.products);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = (plan_id, plan_is_active) => {
    let payload = {
      table_name: "Product_plan_master",
      table_field: "plan_id",
      table_field_val: plan_id,
      table_status_field: "plan_is_active",
      table_status_val: plan_is_active ? false : true,
      table_text: "Plan",
      deleted_by_key: "plan_uu_by",
      delete_active_txt: plan_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllPlans(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    plan_id: "",
    plan_name: "",
    plan_desc: "",
    plan_expiry: "",
    plan_subs_price: "",
    plan_onboarding_charge: "",
    plan_period: "MONTHLY",
    fk_prod_id: currentProductID || ""
  };

  const [formPlan, setFormPlan] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {

    if ((type === "edit", id)) {
      setPlanid(id);
      const filteredPlan = plansTypeList.find((plan) => plan.value === obj.plan_period);
      const initialValues = {
        plan_id: obj.plan_id,
        plan_name: obj.plan_name,
        plan_desc: obj.plan_desc,
        plan_expiry: moment(obj?.plan_expiry).format("YYYY-MM-DD"),
        plan_subs_price: obj.plan_subs_price,
        plan_onboarding_charge: obj.plan_onboarding_charge,
        plan_period: filteredPlan?.value,
        fk_prod_id: obj.fk_prod_id || currentProductID
      };
      setSelectedPlan(filteredPlan);

      setFormPlan(initialValues);
    } else {
      setSelectedPlan([]);
      setPlanid("");
      setFormPlan(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (plan_id, plan_is_active) => {
    setConfirmationModal({ plan_id, plan_is_active, status: true });
  };

  const onDeleteOpenSection = (plan_id, plan_is_deleted) => {
    setRemoveConfirmationModal({ plan_id, plan_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formPlan,
      validationSchema: planMasterSchema,
      onSubmit: async (values, action) => {

        let body = {
          plan_id: plan_id,
          plan_name: values.plan_name,
          plan_desc: values.plan_desc,
          plan_expiry: values.plan_expiry,
          plan_subs_price: values.plan_subs_price,
          plan_onboarding_charge: values.plan_onboarding_charge,
          plan_period: values.plan_period,
          fk_prod_id: values.fk_prod_id || currentProductID
        };

        if (body.plan_name != undefined || body.plan_name != null || body.plan_name != "") {
          setPlanList([]);
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          let { data, status, message } = await planServices.SavePlan(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllPlans(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllPlans(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onDeletePlan = async (plan_id) => {
    let { data, status } = await planServices.deletePlan({ plan_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllPlans(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ plan_id: null, status: false });
  };


  const handleExport = (excel_type) => {
    if (!planList || planList.length === 0) {
      toast.error("Plan list is empty!");
      return;
    }
    if (planList?.length > 0) {
      let newSheetdata = [];
      planList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          plan_id: obj?.plan_id || '-',
          fk_prod_id: obj?.fk_prod_id || '-',
          prod_title: capitalizeFirstLetter(obj?.product_master_relation?.prod_title) || '-',
          plan_name: capitalizeFirstLetter(obj?.plan_name) || '-',
          plan_period: capitalizeFirstLetter(obj?.plan_period) || '-',
          plan_subs_price: obj?.plan_subs_price || '-',
          plan_desc: obj?.plan_desc || '-',
          plan_expiry: moment(obj?.plan_expiry).format("DD-MM-YYYY") || '-',
          prod_created_at: moment(obj?.plan_created_at).format("DD-MM-YYYY") || '-',
          Status: obj?.plan_is_active == true ? 'Active' : 'In-Active' || '-',
          user_username: obj?.mplan_cu_relation?.user_username || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Plan_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'Plan ID', 'Product ID', 'Product Name', 'Plan Name', 'Plan Type', 'Plan Price', 'Plan Description', 'Plan Expiry', 'Created Date', 'Status', 'Created By']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }



  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.plan_is_active ? "Disable " : "Enable") +
          " this Plan?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.plan_id,
            confirmationModal.plan_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.plan_is_deleted
            ? " Undo"
            : "Delete") +
          " this Plan?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeletePlan(removeConfirmationModal.plan_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Plan List</h1>
      </div>
      <div className="mt-4 flex">
        {/* {allowedActions.filter((data) => data.permission_id == 60)?.length >
          0 ? ( */}
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
        >
          Add Plan
        </Link>
        {/* ) : null} */}
        {/* {allowedActions.filter((data) => data.permission_id == 65)?.length > 0 && planList?.length > 0 ? ( */}
        <Link
          to={"#"}
          onClick={() => handleExport('xlsx')}
          type="button"
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
        >
          Download Excel
        </Link>
        {/* ) : null} */}
        {/* {allowedActions.filter((data) => data.permission_id == 66)?.length > 0 ? ( */}
        <> <button
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
          disabled={importLoader}
          onClick={handlePlanButtonClick}
          aria-live="polite" // Optional for screen readers
        >
          {importLoader ? (
            <>
              <FaSpinner color="gold" className="spinner animate-spin" />
              <span className="ml-2">Uploading Please Wait ...</span>
            </>
          ) : (
            "Upload Plan"
          )}
        </button>
          <input
            accept=".xlsx, .xls"
            onChange={handlePlanImport}
            type="file"
            name="user_excel"
            autoComplete="off"
            ref={fileInputRef}
            className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
            style={{ display: 'none' }} // Hide the file input
          /></>
        {/* ) : null} */}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={plan_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={planList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {plan_id ? "Update" : "Add"} Plan
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                            <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="chapter_title"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Product Name<span className="text-red-600">*</span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <select
                                    disabled
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                                    name="fk_prod_id"
                                    id="fk_prod_id"
                                    value={values?.fk_prod_id || currentProductID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value={null}> Select Product </option>
                                    {prodList?.map((s, i) => (
                                      <option key={s.prod_id} value={s.prod_id}>
                                        {s.prod_title}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.fk_prod_id && touched.fk_prod_id ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.fk_prod_id.replace("fk_prod_id", "Product Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.plan_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Plan Name"
                                    name="plan_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.plan_name &&
                                    touched.plan_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_name.replace("plan_name", "Plan Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_period"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Type
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="plan_period"
                                    placeholder={
                                      <span>
                                        Select Type
                                        <span className="text-red-600">
                                          *
                                        </span>
                                      </span>
                                    }
                                    onChange={(e) => {
                                      values.plan_period = e.value;
                                      handlePlanType(e);
                                    }}
                                    value={selectedPlan}
                                    components={animatedComponents}
                                    options={plansTypeList}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                                  />

                                  {errors.plan_period && touched.plan_period ? (
                                    <p className="text-red-600 text-sm">{errors.plan_period}</p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_expiry"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Expiry Date
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.plan_expiry}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="date"
                                    min={new Date().toISOString().split('T')[0]}
                                    placeholder="Date"
                                    name="plan_expiry"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  />
                                  {errors.plan_expiry &&
                                    touched.plan_expiry ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_expiry}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_subs_price"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Subscription Price
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.plan_subs_price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Price"
                                    name="plan_subs_price"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  />
                                  {errors.plan_subs_price &&
                                    touched.plan_subs_price ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_subs_price}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="plan_desc"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Description
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    value={values.plan_desc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Description"
                                    name="plan_desc"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                    rows="2"
                                  />
                                  {errors.plan_desc &&
                                    touched.plan_desc ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.plan_desc}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md bbt bg-[#27ff10] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : "bg-cyan-700"
                              }`}
                          >
                            {plan_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default PlanList;