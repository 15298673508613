import React, { useEffect, useState, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { pcGroupSchema } from "../../../../schemas";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import RemoveConfirmationModal from "../../../../components/DeleteConfirmationModal";
import DeleteAccConfirmationModal from "../../../../components/DeleteAccConfirmationModal";
import { FaSpinner } from "react-icons/fa";
import GroupServices from "../../../../ApiServices/GroupServices";
import GroupAccServices from "../../../../ApiServices/GroupAccServices";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import FSLoader from "../../../../components/FSLoader";
import GroupCard from './PCGroupCard';
import emptyImg from "../../../../assets/images/empty_common.png"
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Select from "react-select";
import CopyAccountServices from "../../../../ApiServices/CopyAccountServices";
import LoadingButton from "../../../../components/LoadingButton";
import Editor from '@monaco-editor/react';

function PCGroupList() {
	const pages = [{ title: "Paramater Config", href: "/param-config", module_id: 12 }];
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [groupList, setGroupList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isFSLoading, setSFLoading] = useState(false);
	const [isSaveBtnApiCall, setIsSaveBtnApiCall] = useState(false);
	const [loadingRemoveGrpIndex, setLoadingRemoveGrpIndex] = useState(-1);
	const [openAddGroup, setOpenAddGroup] = useState(false);
	const [openAddGroupAcc, setOpenAddGroupAcc] = useState(false);
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [pgrp_id, setGroupid] = useState(0);
	const [gam_id, setGroupMapid] = useState(0);
	const [groupMapShowType, setGroupMapShowType] = useState(-1);
	const [addCurrentAccStatus, setAddCurrentAccStatus] = useState(-1);
	const [accountList, setAccountList] = useState([]);
	const [accountSelected, setAccountSelected] = useState(null);
	const [currentAddAccGroup, setCurrentAddAccGroup] = useState(null);
	const [senderVal, setSenderVal] = useState(null);
	const [receiverVal, setReceiverVal] = useState(null);
	const [riskType, setRiskType] = useState(null);
	const [multiplier, setMultiplier] = useState(null);
	const [configData, setConfigData] = useState("");
	const editorRef = useRef(null);
	const [initValAddAcc, setInitValAddAcc] = useState({
		gam_risk_type: "NONE",
		gam_multiplier: 0,
		gam_acc_type: "",
		gam_ss: "",
		gam_rs: "",
	});

	const initialValues = {
		pgrp_id: "",
		pgrp_name: "",
		pgrp_is_active: ""
	};

	const [formGroup, setFormGroup] = useState(initialValues);

	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		pgrp_acc_status: 0,
		mua_acc_login: "",
		gam_id: null,
		fk_pgrp_id: null,
	});
	const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
		status: false,
		pgrp_id: null,
	});
	const [removeAccConfirmationModal, setRemoveAccConfirmationModal] = useState({
		status: false,
		pgam_id: null,
	});


	const OnChangeAccountType = (fk_pgrp_id, gam_is_smo, account, mua_acc_login, accAr, pgrp_acc_status) => {

		let gam_id = account?.gam_id;
		const filterData = accAr?.filter((item) => item.gam_is_smo == 1)
		let title = "Are you sure you want to modify?";
		if (pgrp_acc_status == 1) {
			if (filterData.length > 0) {
				title = "Are you sure you want to Activate Master For Account No : " + (mua_acc_login) + " One Account Already Have Master Function On " + (filterData.length > 0 ? filterData[0]?.mt_user_account_relation?.mua_acc_login : "");
			} else {
				let Status = getStatusTitle(pgrp_acc_status);
				title = "Are you sure you want to Activate " + Status + " For Account No : " + (mua_acc_login);
			}
		} else {
			let Status = getStatusTitle(pgrp_acc_status);
			title = "Are you sure you want to Activate " + Status + " For Account No : " + (mua_acc_login);

		}

		setConfirmationModal({ fk_pgrp_id, gam_id, mua_acc_login, pgrp_acc_status, status: true, title });
	};

	<ConfirmationModal
		title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
		confirmationButtonText="Yes"
		open={confirmationModal.status}
		onDelete={() => {
			onStatusUpdate(
				confirmationModal.fk_pgrp_id,
				confirmationModal.gam_id,
				confirmationModal.acc_status,
				confirmationModal.pgrp_acc_status,
				confirmationModal.mua_acc_login
			);
		}
		}
		setOpen={setConfirmationModal}
	/>

	const onStatusUpdate = async (fk_pgrp_id, gam_id, acc_status, mua_acc_login, pgrp_acc_status) => {
		let payload = {
			gam_id: gam_id,
			fk_pgrp_id: fk_pgrp_id,
			mua_acc_login: mua_acc_login,
			pgrp_acc_status: pgrp_acc_status,
			gam_is_smo: 1,
			StatusTitle: getStatusTitle(pgrp_acc_status)
		}
		let { data, status } = await GroupAccServices.updateGroupAccStatus(payload)
		if (status === 200) {
			if (data.status) {

				toast.success(data.message);
				getAllPCGroup(1);
			}
		} else {
			//toast.error(data.message);
		}
		setConfirmationModal({ fk_pgrp_id: null, gam_id: null, mua_acc_login: "", status: false });
		getAllPCGroup(1);
	}

	const getStatusTitle = (pgrp_acc_status) => {
		if (pgrp_acc_status === 0) {
			return "Off";
		} else if (pgrp_acc_status === 1) {
			return "Master";
		} else if (pgrp_acc_status === 2) {
			return "Slave";
		} else {
			return "";
		}
	};

	const navigate = useNavigate();


	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "EdgeFin  • Parameter Config";
		getAllTradeAcc(1);
		getAllPCGroup(1);
	}, []);

	const getAllTradeAcc = async (payload) => {
		try {
			const res = await CopyAccountServices.getAllCopyTrade(payload)
			if (res.status) {
				setAccountList(res.data.data);
			}
		} catch (e) {
			console.log(e, "error in getAllModules");
			//toast.error("Fatal Error");
		}
	}

	const getAllPCGroup = async (payload) => {
		setIsLoading(true);
		try {
			const res = await GroupServices.getAllPCGroup(payload)
			if (res.status) {
				setGroupList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllModules")
			//toast.error("Fatal Error")
			setIsLoading(false);
		}
	}

	const onAddAccount = (data) => {
		console.log(data);
		setCurrentAddAccGroup(data);
		setOpenAddGroupAcc(true);
	}

	const handleAccStateChange = (data) => {
		setAddCurrentAccStatus(data)
	}

	const handleAccountChange = (data) => {
		setAccountSelected(data);
	}

	const handleSenderSuffixChange = (data) => {
		setSenderVal(data);
	}

	const handleReceiverSuffixChange = (data) => {
		setReceiverVal(data);
	}

	const handleRiskTypeChange = (data) => {
		setRiskType(data);
	}
	const handleMultiplierChange = (data) => {
		setMultiplier(data);
	}


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formGroup,
			validationSchema: pcGroupSchema,
			onSubmit: async (values, action) => {

				setIsSaveBtnApiCall(true);

				let body = {
					pgrp_id: pgrp_id ? pgrp_id : 0,
					pgrp_is_active: true,
					pgrp_name: values.pgrp_name,
					pgrp_config: configData ? configData : "{}",
				};
				if (pgrp_id === undefined || pgrp_id === null || pgrp_id === "") {
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					let { data, status, message } = await GroupServices.savePCGroup(body)
				} else {

					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					let { data, status, message } = await GroupServices.savePCGroup(body)
				}
				action.resetForm();
				getAllPCGroup(3);
				setOpenAddGroup(false);
				if (modalOpenFlage === true) {
					getAllPCGroup(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				setIsSaveBtnApiCall(false);
			},
		});

	const addAccountFormik = useFormik({
		initialValues: initValAddAcc,
		validate: values => {
			const errors = {};
			return errors;
		},
		onSubmit: async values => {

			setIsSaveBtnApiCall(true);

			if (accountSelected) {

				const payload = {
					pgam_id: 0,
					accounts: currentAddAccGroup,
					pgam_data: accountSelected,
					fk_pgrp_id: groupMapShowType == 0 ? initValAddAcc.fk_pgrp_id : currentAddAccGroup ? parseInt(currentAddAccGroup?.pgrp_id) : 0,
					pgam_pgrp_name: groupMapShowType == 0 ? initValAddAcc.gam_pgrp_name : currentAddAccGroup ? currentAddAccGroup?.pgrp_name : "N/A",
				}

				console.log({ payload });

				//gam_id, fk_pgrp_id, fk_mua_id, gam_is_smo, gam_risk_type, gam_multiplier
				const response = await GroupAccServices.savePCMultiGroupAcc(payload);

				if (response.data.status) {
					setOpenAddGroupAcc(false);
					toast.success(response.data.message)
				} else {
					setOpenAddGroupAcc(false);
					toast.error(response.data.message)
				}

				setIsSaveBtnApiCall(false);
			} else {
				setIsSaveBtnApiCall(false);
				alert("Please, Select Account No");
			}
			getAllPCGroup(1);
		},
	});

	const handleClickMenu = (data) => {
		// console.log(data);
		// setGroupMapid(data?.id?.gam_id);
		// return
		// setGroupMapShowType(data.type);
		if (data.type == 1) {
			//Delete
			handleRemoveGroupAcc(data.data);
		}
	}

	const handleEditGroup = (data) => {
		console.log(data);
		setFormGroup({
			pgrp_id: data?.pgrp_id,
			pgrp_name: data?.pgrp_name,
			pgrp_is_active: data?.pgrp_is_active,
		});
		setGroupid(data?.pgrp_id);
		setOpenAddGroup(true);
		setConfigData(JSON.stringify(JSON.parse(data?.pgrp_config), null, 2));
	}

	const handleRemoveGroup = (data, index) => {
		setLoadingRemoveGrpIndex(index);
		let title = "Are you sure you want to Remove Group Data For Group Name " + data?.pgrp_name;
		setRemoveConfirmationModal({ pgrp_id: data?.pgrp_id, status: true, title });
	}

	const onDeleteGroup = async (pgrp_id, pgrp_status) => {
		let payload = {
			pgrp_id: pgrp_id,
			pgrp_status: !pgrp_status,
		}
		let { data, status } = await GroupServices.deletePCGroup(payload);
		// Simulate an async action

		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
				getAllPCGroup(1);
			}
		} else {
			toast.error(data.message);
		}
		setRemoveConfirmationModal({ pgrp_id: null, status: false });
		getAllPCGroup(1);
		setLoadingRemoveGrpIndex(-1);
	}

	const handleRemoveGroupAcc = (data) => {
		console.log({ handleRemoveGroupAcc: data });
		let title = "Are you sure you want to Remove Group Acc No " + data?.pgam_acc_no;
		setRemoveAccConfirmationModal({ pgam_id: data?.pgam_id, status: true, title });
	}

	const onDeleteGroupAcc = async (pgam_id, pgam_status) => {
		let payload = {
			pgam_id: pgam_id,
			pgam_is_deleted: !pgam_status,
		}
		let { data, status } = await GroupAccServices.deletePCGroupAcc(payload);
		// Simulate an async action

		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		}
		setRemoveAccConfirmationModal({ pgam_id: null, status: false });
		getAllPCGroup(1);
	}

	const handleEditorDidMount = (editor, monaco) => {
		editorRef.current = editor;
		//console.log("editor.getValue()=>", editor.getValue())
		editor.onDidChangeModelContent(() => {
			// Update configData state when the editor content changes
			setConfigData(editor.getValue());
		});
		/* if (editorRef.current.getValue() != null && editorRef.current.getValue() != "") {
		  // setConfigData(editorRef.current.getValue());
		  setConfigData(editorRef.current.getValue());
		  console.log(editorRef.current.getValue());
		} */
	}

	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<FSLoader isLoading={isFSLoading} title="Processing..." />

			<Breadcrumb pages={pages} />
			<ConfirmationModal
				title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={confirmationModal.status}
				onDelete={() => {
					onStatusUpdate(
						confirmationModal.fk_pgrp_id,
						confirmationModal.gam_id,
						confirmationModal.acc_status,
						confirmationModal.mua_acc_login,
						confirmationModal.pgrp_acc_status,
					);
					console.log("confirmationModal.mua_acc_login=>", confirmationModal);
				}
				}
				setOpen={setConfirmationModal}
			/>

			<RemoveConfirmationModal
				title={removeConfirmationModal?.title ? removeConfirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={removeConfirmationModal.status}
				onDelete={() => {
					onDeleteGroup(
						removeConfirmationModal.pgrp_id,
						removeConfirmationModal.status,
					);
				}
				}
				setOpen={setRemoveConfirmationModal}
			/>

			<DeleteAccConfirmationModal
				title={removeAccConfirmationModal?.title ? removeAccConfirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={removeAccConfirmationModal.status}
				onDelete={() => {
					onDeleteGroupAcc(
						removeAccConfirmationModal.pgam_id,
						removeAccConfirmationModal.status,
					);
				}
				}
				setOpen={setRemoveAccConfirmationModal}
			/>


			<div className="border-b border-gray-600 pb-5 sm:flex sm:items-center sm:justify-between">
				<h3 className="text-base font-semibold leading-6 text-white">Param Config - Groups</h3>
				<div className="mt-3 sm:ml-4 sm:mt-0">
					<button
						onClick={() => {
							setOpenAddGroup(true)
						}}
						type="button"
						className="inline-flex items-center rounded-md bg-[#27ff10] px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						+ Add New Config Group
					</button>
				</div>
			</div>

			<div className="mt-4 flex">

			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<div className="container mx-auto p-4">
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
						{
							groupList.map((group, ind) => (
								<GroupCard index={ind} deleteIndex={loadingRemoveGrpIndex} key={group.pgrp_id} group={group} OnChangeAccountType={OnChangeAccountType} onAddAccount={onAddAccount} handleClickMenu={handleClickMenu} handleEditGroup={handleEditGroup}
									handleRemoveGroup={handleRemoveGroup}
								/>
							))
						}
					</div>
					{
						groupList.length <= 0 ? (
							<div className="border border-gray-600 bg-gray-800 rounded-md p-4 my-4 w-full flex flex-col text-center items-center justify-between">

								<img src={emptyImg} alt="empty" width="30%" className="text-center items-center justify-between" />
								<p className="text-slate-500">Ops! No Active Goup</p>
								<button
									onClick={() => {
										setOpenAddGroup(true)
									}}
									type="button"
									className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#dbff06] to-[#27ff10] py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
								>
									Let's Add New Group
								</button>

							</div>
						) : null
					}
				</div>
			)}


			{/* Modal for Acc Group */}
			<Transition show={openAddGroup}>
				<Dialog className="relative z-10" onClose={setOpenAddGroup}>
					<TransitionChild
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-800 px-2 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
									<div>
										<div className="mt-2 sm:mt-2">
											<DialogTitle as="h3" className="text-base text-center border-b border-gray-600 font-semibold leading-6 text-gray-100 pb-3">
												{pgrp_id > 0 ? 'Update Group' : 'Add New PC Group'}
											</DialogTitle>
											<div className="mt-2">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														console.log(e);
														handleSubmit(e);
													}}
													className="flex h-full flex-col"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="flex flex-1 flex-col justify-between">
															<div className="p-4 sm:p-6">
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="pgrp_name"
																			className="block text-sm font-medium text-gray-100 mt-2 mb-1"
																		>
																			Group Name
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.pgrp_name}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="text"
																			placeholder="Enter Group Name"
																			name="pgrp_name"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-gray-100 shadow-sm focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{errors.pgrp_name &&
																			touched.pgrp_name ? (
																			<p className="text-red-600 text-sm">
																				{errors.pgrp_name}
																			</p>
																		) : null}
																	</div>
																</div>

																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			className="block text-sm font-medium text-gray-100 mt-2"
																		>
																			Config Data - (JSON/TEXT)
																		</label>
																	</div>
																	<div className="mb-3 sm:col-span-2 sm:mt-0">
																		<Editor
																			height="30vh"
																			Theme="vs-dark"
																			defaultLanguage="json"
																			defaultValue={configData}
																			onMount={handleEditorDidMount}
																			className="border bg-gray-600"
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 pb-2">
														<button
															type="button"
															className="rounded-md border border-gray-600 bg-gray-800 py-2 px-4 text-sm font-medium text-gray-100 shadow-sm hover:bg-gray-700"
															onClick={() => {
																setFormGroup({
																	pgrp_id: 0,
																	pgrp_name: "",
																	pgrp_is_active: true,
																});
																setGroupid(null);
																setConfigData("");
																setOpenAddGroup(false);
															}}
														>
															Cancel
														</button>
														{
															isSaveBtnApiCall ? (
																<LoadingButton title=" Saving" />
															) : (
																<button
																	type="submit"
																	className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b bg-[#27ff10] py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-cyan-700"
																>
																	{pgrp_id > 0 ? 'Update' : 'Add'} Group
																</button>
															)
														}
													</div>
												</form>
											</div>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>


			{/* Modal for Add Acc in Group */}
			<Transition show={openAddGroupAcc}>
				<Dialog className="relative z-10" onClose={setOpenAddGroupAcc}>
					<TransitionChild
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-800 bg-opacity-75 backdrop-blur-sm transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-900 px-2 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
									<div>
										<div className="mt-2 sm:mt-2">
											<DialogTitle as="h3" className="text-base text-center border-b border-gray-600 font-semibold leading-6 text-gray-300 pb-3">
												{groupMapShowType == 2 ? 'Show Account Details' : gam_id <= 0 ? 'Link account to group > ' + currentAddAccGroup?.pgrp_name : 'Update Account'}
											</DialogTitle>
											<div className="mt-2">
												<form
													onSubmit={addAccountFormik.handleSubmit}
													className="flex h-full flex-col"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="flex flex-1 flex-col justify-between">
															<div className="p-4 sm:p-6">
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="pgrp_name"
																			className="block text-sm font-medium text-gray-300 mt-2 mb-1"
																		>
																			Choose Account
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<Select
																			isMulti
																			name="Account"
																			defaultValue={accountSelected}
																			value={accountSelected}
																			onChange={(option) => {
																				handleAccountChange(option)
																			}}
																			options={accountList}
																			classNamePrefix="select"
																			className="basic-multi-select"
																			menuPortalTarget={document.body}
																			styles={{
																				menuPortal: (base) => ({
																					...base,
																					zIndex: 9999,
																				}),
																			}}
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 pb-2">
														<button
															type="button"
															className="rounded-md border border-gray-600 bg-gray-800 py-2 px-4 text-sm font-medium text-gray-300 shadow-sm hover:bg-gray-700"
															onClick={() => {
																setInitValAddAcc({
																	gam_risk_type: "NONE",
																	gam_multiplier: 0
																})
																setCurrentAddAccGroup(null);
																setAddCurrentAccStatus(-1);
																handleAccountChange(null);
																setOpenAddGroupAcc(false);
																setGroupMapShowType(-1);
																setGroupMapid(0);
															}}
														>
															Cancel
														</button>
														{groupMapShowType !== 2 ? (
															<>
																{
																	isSaveBtnApiCall ? (
																		<LoadingButton title=" Saving" />
																	) : (
																		<button
																			type="submit"
																			className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b bg-[#27ff10] py-2 px-4 text-sm font-medium text-black shadow-sm "
																		>
																			{gam_id > 0 ? 'Update' : 'Add'} Account
																		</button>
																	)
																}
															</>
														) : (<></>)}
													</div>
												</form>
											</div>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>



		</div>
	);
}
export default PCGroupList;
