import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import axios from "axios";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import googleServices from "../../../ApiServices/googleServices";

const Google2FASetup = ({ on2FAEnabled }) => {
    const [secret, setSecret] = useState("");
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [token, setToken] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    // Fetch the 2FA secret and generate a QR code
    const generate2FASetup = async () => {
        try {
            setDisableSubmitButton(true);

            let res = await googleServices.generate2FA()
            console.log('res=>',res)
            if (res.data.status) {
                setSuccess("2FA has been successfully enabled!");
                setError("");
                setSecret(res.data.data.secret);
                setQrCodeUrl(res.data.data.otpauthUrl);
                if (res.data.status) {
                    toast.success(res.data.message);
                } else {

                    toast.success(res.data.message);
                    setErrorMessage(res.data.message);
                    setTimeout(() => {
                        setErrorMessage('')
                    }, 1000)
                    setIsLoading(false);
                    setDisableSubmitButton(false);
                }
            } else {
                setError("Invalid token. Please try again.");
                setSuccess("");
                setDisableSubmitButton(false);
            }
        } catch (err) {
            setError("Failed to generate 2FA setup. Please try again.");
        }
    };

    // Verify the entered token
    const verify2FAToken = async () => {
        try {
            let body={ token, secret };
            let { data, status, message } = await googleServices.verify2FA(body)
            if (status === 200) {
                setSuccess("2FA has been successfully enabled!");
                setError("");
                if (on2FAEnabled) on2FAEnabled(true);
                if (data.status) {
                    toast.success(data.message);
                } else {

                    toast.success(data.message);
                    setErrorMessage(data.message);
                    setTimeout(() => {
                        setErrorMessage('')
                    }, 1000)
                    setIsLoading(false);
                    setDisableSubmitButton(false);
                }
            } else {
                setError("Invalid token. Please try again.");
                setSuccess("");
                setDisableSubmitButton(false);
            }

        } catch (err) {
            setError("Error verifying token. Please try again.");
            setSuccess("");
        }
    };

    return (
        <div>
            <h3>Setup Google Authenticator 2FA</h3>
            <button onClick={generate2FASetup} className="btn-primary">
                Generate QR Code
            </button>

            {qrCodeUrl && (
                <div style={{ marginTop: "20px" }}>
                    <p>Scan this QR code with Google Authenticator:</p>
                    <QRCodeCanvas value={qrCodeUrl} size={200} />
                </div>
            )}

            {qrCodeUrl && (
                <div style={{ marginTop: "20px" }}>
                    <p>Enter the token generated by Google Authenticator:</p>
                    <input
                        type="text"
                        placeholder="Enter 2FA token"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        className="input-field"
                    />
                    <button onClick={verify2FAToken} className="btn-primary">
                        Verify Token
                    </button>
                </div>
            )}

            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
        </div>
    );
};

export default Google2FASetup;
