import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import { Await, Link, useNavigate, useParams } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { useFormik } from "formik";
import { profileSchema } from "../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from 'moment';

const Profile = () => {
  const pages = [
    {
      title: "User Profile",
      href: "/profile",
      module_id: 8,
    },
  ];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [user_id, setUserId] = useState(localStorage.getItem("user_id"));
  const initialValues = {
    user_id: null,
    user_password: null,
    conf_password: null,
  };

  const [formUser, setFormUser] = useState(initialValues);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    MasterServices.getUserProfile(localStorage.getItem("user_id"))
      .then((res) => {
        const { data, status } = res;
        if (status) {
          setUserProfile(data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formUser,
      validationSchema: profileSchema,
      onSubmit: async (values, action) => {
        let body = {
          user_id: user_id ? user_id : null,
          user_password: values.user_password,
          conf_password: values.conf_password,
        };
        const response = await MasterServices.SaveResetPassword(body);

        if (response.data.status) {
          action.resetForm();
          setFormUser(initialValues);
          toast.success(response.data.message);
          navigate("/user-list");
        } else {
          toast.error(response.data.message);
        }
      },
    });

  return (
    <>
      {!loading ? (

        <div className="border-b border-gray-700 bg-gray-800 rounded-md px-4 py-5 sm:px-6">
          <Breadcrumb pages={pages} />
          <div className="border-b border-gray-700 py-5">
            <h1 className="text-xl font-semibold text-white">
              Account Profile
            </h1>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="flex flex-col">
              <div>
                <div className="grid grid-cols-3 mt-5 gap-5 lg:w-full w-full">
                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      User Name : <b>{userProfile?.user_username}</b>
                    </label>
                  </div>

                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      First Name : <b>{userProfile?.user_first_name}</b>
                    </label>
                  </div>

                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      Last Name : <b>{userProfile?.user_last_name}</b>
                    </label>
                  </div>

                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      Email : <b>{userProfile?.user_email}</b>
                    </label>
                  </div>

                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      Mobile : <b>{userProfile?.user_mobile}</b>
                    </label>
                  </div>
                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      Role : <b>{userProfile?.m_role_relation?.role_name}</b>
                    </label>
                  </div>

                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      Enter Password
                    </label>
                    <div>
                      <input
                        value={values.user_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="user_password"
                        type="password"
                        placeholder="Enter Password"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm`}
                      />
                      {errors.user_password && touched.user_password ? (
                        <div className="text-sm text-red-600">
                          {errors.user_password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-white whitespace-nowrap">
                      Confirm Password
                    </label>
                    <div>
                      <input
                        value={values.conf_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="conf_password"
                        type="password"
                        placeholder="Enter Confirm Password"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm`}
                      />
                      {errors.conf_password && touched.conf_password ? (
                        <div className="text-sm text-red-600">
                          {errors.conf_password}
                        </div>
                      ) : null}
                    </div>
                  </div>

                </div>
              </div>
              <div>

              </div>
            </div>

            <div className="mt-10 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className={"inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#dbff06] focus:outline-none focus:ring-2 focus:ring-[#dbff06] focus:ring-offset-2"}
              >
                {disableSubmitButton ? <FaSpinner color="gold" /> : "Update"}
              </button>
            </div>
          </form>
        </div>


      ) : (

        <FallingLinesLoader />
      )}
    </>
  )
}

export default Profile