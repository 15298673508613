/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product`, payload);
    return response;
  },

  async getAllProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/product/?search=${payload}`);
    return response;
  },
  async getAllSingleProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/product/${payload}`);
    return response;
  },
  async setProductStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/status`, payload);
    return response;
  },
  async deleteProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/remove`, payload);
    return response;
  },

  async importProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/import`, payload);
    return response;
  },
  //  product Section End
}