/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveChapter(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course-chapter`, payload);
    return response;
  },

  async getAllChapter(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/course-chapter/?search=${payload}`);
    return response;
  },
  async getAllSingleChapter(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/course-chapter/${payload}`);
    return response;
  },
  async setChapterStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course-chapter/status`, payload);
    return response;
  },
  async deleteChapter(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course-chapter/remove`, payload);
    return response;
  },

  async importChapter(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course-chapter/import`, payload);
    return response;
  },
  //  product Section End
}