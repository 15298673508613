import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toast";
import chatTopicServices from "../../../ApiServices/chatTopicServices";
import messageServices from "../../../ApiServices/messageServices";

const ChatInterface = () => {
  const [topics, setTopics] = useState([]); // For topics
  const [selectedTopic, setSelectedTopic] = useState(null); // Selected topic
  const [messages, setMessages] = useState([]); // Messages for the selected topic
  const [isLoading, setIsLoading] = useState(true);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  useEffect(() => {
    document.title = "EdgeFin •  Chats";
    getAllTopics(1);
  }, []);

  const getAllTopics = async (payload) => {
    setIsLoading(true);
    try {
      const res = await chatTopicServices.getAllTopic(payload)
      if (res.status) {
        console.log("res.data.data=>", res.data.data?.topics)
        setTopics(res?.data?.data?.topics);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }


  // Validation schema for message form
  const messageSchema = Yup.object().shape({
    msg_message: Yup.string()
      .required("Message is required")
      .min(1, "Message cannot be empty"),
  });

  // Fetch messages when a topic is selected
  const getAllTopicsMessages = async (fk_sct_id) => {
    let payload = {
      fk_sct_id: fk_sct_id,
      msg_is_active: 1,
      msg_is_deleted: 0,
    }
    setIsLoading(true);
    try {
      const res = await messageServices.getAllMSG(payload)
      if (res.status) {
        console.log("res.data.data=>", res.data.data?.messages)
        setMessages(res?.data?.data?.messages);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }


  const handleSendMessage = async (values, { resetForm }) => {
    setDisableSubmitButton(true)
    const payload = {
      ...values,
      fk_sct_id: selectedTopic.sct_id,
      fk_sender_id: 1, // Replace with actual sender ID
    };
    setIsLoading(true);

    try {
      let res = await messageServices.SaveMSG(payload)
      if (res.status) {
        console.log("res.data.data=>", res.data.data)
        setMessages((prev) => [...prev, res.data.data]);
        setDisableSubmitButton(false)
        resetForm();
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
      setDisableSubmitButton(false)
    }

  };

  return (
    <div className="max-w-6xl mx-auto p-4 text-white bg-gray min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Support Chat</h1>

      {/* Topic Selection */}
      {!selectedTopic && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {topics.length === 0 ? (
            <p>Loading topics...</p>
          ) : (
            topics.map((topic) => (
              <div
                key={topic.sct_id}
                onClick={() => {
                  setSelectedTopic(topic);
                  getAllTopicsMessages(topic.sct_id);
                }}
                className="p-4 bg-gray-800 rounded-lg shadow-lg cursor-pointer hover:bg-gray-700"
              >
                <h2 className="text-xl font-semibold mb-2">{topic.sct_title}</h2>
                <p className="text-gray-400">{topic.sct_description}</p>
              </div>
            ))
          )}
        </div>
      )}

      {/* Chat Interface */}
      {selectedTopic && (
        <div className="mt-6">
          <button
            onClick={() => setSelectedTopic(null)}
            className="text-gray-500 mb-4 hover:underline"
          >
            &larr; Back to Topics
          </button>

          <h2 className="text-2xl font-bold mb-4">{selectedTopic.sct_title}</h2>

          <div className="border border-gray-700 rounded-lg p-4 h-64 overflow-y-auto bg-gray-900">
            {isLoading ? (
              <p>Loading messages...</p>
            ) : messages.length === 0 ? (
              <p>No messages yet for this topic.</p>
            ) : (
              messages.map((msg) => (
                <div
                  key={msg.msg_id}
                  className={`p-2 rounded-lg mb-2 ${msg.fk_sender_id === 1
                    ? "bg-gray-700 text-right"
                    : "bg-gray-700"
                    }`}
                >
                  <p>{msg.msg_message}</p>
                  <small className="text-gray-400 text-sm">
                    {new Date(msg.msg_created_at).toLocaleString()}
                  </small>
                </div>
              ))
            )}
          </div>

          <Formik
            initialValues={{ msg_message: "" }}
            validationSchema={messageSchema}
            onSubmit={handleSendMessage}
          >
            {({ errors, touched }) => (
              <Form className="flex mt-4">
                <div className="flex-1">
                  <Field
                    name="msg_message"
                    as="textarea"
                    placeholder="Type your message..."
                    className="w-full border border-gray-700 bg-gray-800 rounded-lg p-2 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                  />
                  {errors.msg_message && touched.msg_message && (
                    <p className="text-red-500 text-sm mt-1">{errors.msg_message}</p>
                  )}
                </div>
                <button
                  disabled={disableSubmitButton}
                  type="submit"
                  className={`ml-2 bg-gray-500 text-white p-2 rounded-lg hover:bg-gray-600 ${disableSubmitButton
                    ? "bg-gray-700 hover:bg-gray-700"
                    : ""
                    }`}
                >
                  {disableSubmitButton ? (
                    <FaSpinner size={20} color="gold" />
                  ) : (<FiSend size={20} />)}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default ChatInterface;
