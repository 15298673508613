/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic`, payload);
    return response;
  },

  async getAllTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/support-chat-topic/?search=${payload}`);
    return response;
  },
  async getAllSingleTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/support-chat-topic/${payload}`);
    return response;
  },
  async setTopicStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic/status`, payload);
    return response;
  },
  async deleteTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic/remove`, payload);
    return response;
  },

  async importTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic/import`, payload);
    return response;
  },
  //  product Section End
}