/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveCourse(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course`, payload);
    return response;
  },

  async getAllCourse(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/course/?search=${payload}`);
    return response;
  },
  async getAllSingleCourse(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/course/${payload}`);
    return response;
  },
  async setCourseStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course/status`, payload);
    return response;
  },
  async deleteCourse(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course/remove`, payload);
    return response;
  },

  async importCourse(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/course/import`, payload);
    return response;
  },
  //  product Section End
}